<template>
  <v-sheet class="transparent pa-0">
    <v-sheet class="transparent charcoalTile pt-2" rounded>
      <v-card-actions class="pa-0">
        <v-checkbox
          v-if="hasIron || hasMovement"
          class="mt-0"
          dark
          off-icon="mdi-chevron-down-circle"
          on-icon="mdi-chevron-up-circle"
          @click="showStats = !showStats"
          label="Show Monthly Stats"
        />
        <v-spacer />
        <v-switch
          class="mt-0"
          dark
          v-model="metric"
          color="accent"
          label="Metric?"
          @change="loadBasic"
        />
      </v-card-actions>
    </v-sheet>
    <v-sheet
      class="charcoal px-1 py-1"
      v-if="showStats && !timedout && hasIron && hasMovement"
    >
      <v-row dense v-if="loaded">
        <v-col cols="12" md="6" class="my-1">
          <v-card
            class="px-2"
            color="charcoal"
            rounded
            flat
            :style="charcoalTileMenu"
          >
            <v-sheet class="transparent silver--text text--darken-1" tile>
              <template v-if="volumeStats.volume_metric > 0">
                <v-card-actions :class="textSize + ' pl-0 pb-0 paper--text'">
                  PUMPING IRON
                  <v-spacer />
                  <v-icon color="paper" x-large>
                    mdi-arm-flex
                  </v-icon>
                </v-card-actions>
                <v-card-title :class="textSizeXSmall + ' pa-1 pb-1  pl-2'">
                  <v-divider class="charcoal lighten-1 mr-2" />
                  In the last 30 days
                  <v-divider class="charcoal lighten-1 ml-2" />
                </v-card-title>

                <v-card-text
                  :class="textSizeXSmall + ' silver--text pl-0 py-0'"
                >
                  <span class="  pl-2 smallLabel">
                    Volume:
                  </span>
                  {{ parseFloat(volumeStats.volume_imperial).toLocaleString() }}
                  lbs /
                  {{ parseFloat(volumeStats.volume_metric).toLocaleString() }}
                  kg <br />
                  <span class="  pl-2 smallLabel">
                    Selfs:
                  </span>
                  {{ parseFloat(volumeStats.selfs).toLocaleString() }}
                  selfs <br />
                  <span class="  pl-2 smallLabel">
                    Reps:
                  </span>
                  {{ parseFloat(reps.reps).toLocaleString() }}
                  <br />
                  <span class="   pl-2 smallLabel">
                    Time:
                  </span>
                  {{ volumeStats.duration }} hours of weightlifting
                </v-card-text>
                <v-divider class="my-1 ml-2" />
                <v-card-actions
                  :class="textSizeSmall + ' pb-2 pt-0 success--text'"
                >
                  AVG Workout Length
                  <v-spacer />
                  {{ volumeStats.workout_length }} minutes
                </v-card-actions>
              </template>
              <template v-else>
                <v-card-text :class="textSize + ' silver--text pa-0 pt-2'">
                  NO PAIN, NO GAIN!
                </v-card-text>
                <v-card-text
                  :class="textSizeXSmall + ' silver--text pa-0 mt-n2 '"
                >
                  Nothing to see here yet. Get to work.
                </v-card-text>
                <v-card-actions class="pa-0 mt-2">
                  <v-spacer />
                  <BaseActionButton
                    label="Workout"
                    icon="mdi-plus-circle-outline"
                    class="paper--text mr-n2"
                    text
                    dark
                    large
                    @clickedThis="$router.push('/myworkouts')"
                  />
                </v-card-actions>
              </template>
            </v-sheet>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="my-1">
          <v-card
            flat
            class="px-2"
            color="charcoal"
            rounded
            :style="charcoalTileMenu"
          >
            <v-sheet class="transparent silver--text text--darken-1" tile>
              <template v-if="moving != null && moving.distance_metric != null">
                <v-card-actions :class="textSize + ' pl-0 pb-0 paper--text'">
                  MOVEMENT
                  <v-spacer />
                  <v-icon color="paper" x-large>
                    mdi-run-fast
                  </v-icon>
                </v-card-actions>
                <v-card-title :class="textSizeXSmall + ' pa-1 pb-1  pl-2'">
                  <v-divider class="charcoal lighten-1 mr-2" />
                  In the last 30 days
                  <v-divider class="charcoal lighten-1 ml-2" />
                </v-card-title>

                <v-card-text
                  :class="textSizeXSmall + ' silver--text pl-0 py-0'"
                >
                  <span class="  pl-2 smallLabel">
                    Distance:
                  </span>
                  {{ moving.distance_imperial }} miles /
                  {{ moving.distance_imperial }} km <br />
                  <span class="pl-2 smallLabel">
                    Time:
                  </span>
                  {{ moving.hours }} hours of movement<br />
                  <span class="  pl-2 smallLabel">
                    Burned:
                  </span>
                  {{ parseFloat(moving.calories).toLocaleString() }}
                  calories <br />
                  <span class="  pl-2 smallLabel">
                    Melted:
                  </span>
                  {{ moving.burned }} lbs of fat
                </v-card-text>
                <v-divider class="my-1 ml-2" />
                <v-card-actions
                  :class="textSizeSmall + ' pb-2 pt-0 success--text'"
                >
                  AVG Calories Burned
                  <v-spacer />
                  {{ moving.calories_per_minute }} per minute
                </v-card-actions>
              </template>
              <template v-else>
                <v-card-text :class="textSize + ' silver--text pa-0 pt-2'">
                  WOW! Just WOW!
                </v-card-text>
                <v-card-text
                  :class="textSizeXSmall + ' silver--text pa-0 mt-n2 '"
                >
                  So much empty. Track an activity now!
                </v-card-text>
                <v-card-actions class="pa-0 mt-2">
                  <v-spacer />
                  <BaseActionButton
                    label="Activity"
                    icon="mdi-plus-circle-outline"
                    class="paper--text mr-n2"
                    text
                    dark
                    large
                    @clickedThis="$router.push('/activities')"
                  />
                </v-card-actions>
              </template>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
    <v-progress-linear
      color="cyan"
      indeterminate
      rounded
      height="1"
      v-if="!loaded"
    />
    <template v-else>
      <v-row dense class="mt-2">
        <v-col cols="6">
          <v-sheet
            min-height="210"
            class="charcoal silver--text pa-2 rounded-lg charcoalTileMenu"
          >
            <v-card-actions class="pa-0">
              <span class="font-weight-bold paper--text">Measurements</span>
              <v-spacer />
              <v-icon color="paper" @click="showAddMeasurement"
                >mdi-plus-circle</v-icon
              >
            </v-card-actions>
            <v-spacer />
            <template v-if="basic?.weight > 0 && basic?.height > 0">
              <template v-if="metric">
                <span class="text-h5 nicegreen--text"> {{ cm }} cm </span>
                <span class="silver--text">({{ feet }}' {{ inches }}'')</span>
              </template>
              <template v-else>
                <span class="text-h4 nicegreen--text">
                  {{ feet }}' {{ inches }}''
                </span>
                <span class="silver--text">({{ cm }} cm)</span>
              </template>
              <v-spacer />
              <span class="text-h4 nicegreen--text">
                {{ oneDigit(getWeight) }} {{ weightUnit(metric) }}</span
              >
              <v-spacer />
              <span class="text-h6" v-if="basic.bmi > 0">
                BMI {{ oneDigit(basic.bmi) }} %</span
              >
              <v-spacer />
              <span class="text-h6" v-if="basic.bfp > 0">
                BFP {{ oneDigit(basic.bfp) }} %</span
              >
            </template>
            <template v-else>
              <span class="caption silver--text">
                No measurements available. Please add one now.
              </span>
            </template>
          </v-sheet>
        </v-col>
        <v-col cols="6">
          <v-sheet
            min-height="210"
            class="charcoal silver--text pa-2 rounded-lg charcoalTileMenu"
            v-if="loaded"
          >
            <v-card-actions class="pa-0">
              <span class="font-weight-bold paper--text">Nutrition</span>
              <v-spacer />
              <v-icon color="paper" @click="showAddFood"
                >mdi-plus-circle</v-icon
              >
            </v-card-actions>
            <v-spacer />
            <template v-if="energyIn">
              <span class="text-h4 nicegreen--text">
                {{ energyIn.calories }}</span
              >
              <span class="silver--text"> kCal</span>

              <v-row dense>
                <v-col cols="12">
                  <v-progress-linear
                    height="22"
                    rounded
                    background-color="charcoal lighten-1"
                    color="hybrid lighten-1"
                    :value="Math.round(energyIn.percentage_fats)"
                  >
                    <template v-slot:default="{ value }">
                      <div class="progress-bar-text caption paper--text">
                        Fats {{ Math.round(energyIn.fats) }} g, {{ value }}%
                        KCal
                      </div>
                    </template>
                  </v-progress-linear>
                </v-col>

                <v-col cols="12">
                  <v-progress-linear
                    height="22"
                    rounded
                    background-color="charcoal lighten-1"
                    color="gold darken-1"
                    :value="Math.round(energyIn.percentage_carbs)"
                  >
                    <template v-slot:default="{ value }">
                      <div class="progress-bar-text caption paper--text">
                        Carbs {{ Math.round(energyIn.carbs) }} g, {{ value }}%
                        KCal
                      </div>
                    </template>
                  </v-progress-linear>
                </v-col>

                <v-col cols="12">
                  <v-progress-linear
                    height="22"
                    rounded
                    background-color="charcoal lighten-1"
                    color="progressActive darken-2"
                    :value="Math.round(energyIn.percentage_proteins)"
                  >
                    <template v-slot:default="{ value }">
                      <div class="progress-bar-text caption paper--text">
                        Proteins {{ Math.round(energyIn.proteins) }} g,
                        {{ value }}% KCal
                      </div>
                    </template>
                  </v-progress-linear>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <span class="caption silver--text">
                No food was tracked today. Start tracking now.
              </span>
            </template>
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <v-sheet
            min-height="200"
            class="charcoal silver--text pa-2 rounded-lg charcoalTileMenu"
            v-if="loaded"
          >
            <v-card-actions class="pa-0">
              <span class="font-weight-bold paper--text">Your Strength</span>
              <v-spacer />
              <v-icon color="paper" @click="$router.push('/mystrength')">
                mdi-plus-circle
              </v-icon>
            </v-card-actions>
            <v-spacer />
            <template v-if="strength && strength.length > 0">
              <v-card-text class="px-0 silver--text caption">
                Latest 1RM (one rep max) tested.
              </v-card-text>
              <v-row dense class=" mt-2 ">
                <v-col cols="12" class="pa-0">
                  <v-card
                    rounded
                    elevation="0"
                    class=" py-0 mb-2 mr-1 transparent"
                    v-for="item in strength"
                    :key="item.exercise_name"
                  >
                    <v-card-text class="py-0 px-1">
                      <span class="silver--text">
                        <span class="paper--text">
                          {{ capitalize(item.name) }}:
                        </span>
                        {{ oneDigit(item.imperial) }} lbs /
                        {{ oneDigit(item.metric) }} kg
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <span class="caption silver--text">
                You strength is not yet tracked. Add a 1RM (one rep max) to
                start tracking. You can do that on your MyStrength screen or
                during a workout.
              </span>
            </template>
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <v-sheet
            min-height="200"
            class="charcoal silver--text pa-2 rounded-lg charcoalTileMenu"
            v-if="loaded"
          >
            <v-card-actions class="pa-0">
              <span class="font-weight-bold paper--text"
                >Latest Activities</span
              >
              <v-spacer />
              <v-icon color="paper" @click="showAddActivity"
                >mdi-plus-circle</v-icon
              >
            </v-card-actions>
            <v-spacer />
            <template v-if="lastActivitiesList.length > 0">
              <v-row dense class=" mt-2 ">
                <v-col cols="12" class="pa-0">
                  <v-card
                    rounded
                    elevation="0"
                    class=" py-0 mb-2 mx-1 transparent"
                    v-for="item in lastActivitiesList"
                    :key="item.name"
                  >
                    <v-card-text class="py-0 pr-2 pl-0">
                      <v-icon size="30" color="paper" class="mr-1">
                        {{ item.icon }}</v-icon
                      >
                      <span class="paper--text text-body-2"
                        >{{ item.type }}
                      </span>
                      <v-spacer />

                      <span class="silver--text caption">
                        {{ capitalize(trimString(item.text, 50)) }}
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <span class="caption silver--text">
                No activies tracked yet. Start tracking now.
              </span>
            </template>
          </v-sheet>
        </v-col>
      </v-row>
    </template>
  </v-sheet>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  components: {},
  name: 'MovementStats',
  mixins: [util],
  data() {
    return {
      lastActivitiesList: [],
      strength: null,
      basic: {},
      loaded: false,
      showStats: false,
      feet: 0,
      inches: 0,
      cm: 0,
      metric: false,
      metricWeight: 0,
      imperialWeight: 0,
      hasIron: false,
      hasMovement: false,
      energyIn: null
    }
  },
  beforeMount() {
    this.metric = this.useMetric
    this.api = axios.create({
      baseURL: this.baseURL,
      timeout: this.reqTimeout
    })

    this.refreshDashboard()
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  props: {
    reload: {
      type: Boolean,
      default: false
    },
    forceReload: {
      type: Number,
      default: 0
    }
  },
  watch: {
    reload(newVal) {
      if (newVal) {
        this.refreshDashboard()
      }
    },
    forceReload() {
      this.refreshDashboard()
    }
  },
  computed: {
    getWeight() {
      if (this.metric) return this.metricWeight
      else return this.imperialWeight
    },

    ...appConfig
  },
  methods: {
    receiveMessage(event) {
      // Check the message content and show the dialog if it matches
      if (event.data === 'meal_added') {
        this.loadDailyEnergyIn()
      }
      if (event.data === 'activity_added') {
        this.loadLastActivities()
      }
      if (event.data === 'measurement_added') {
        this.loadBasic()
      }
      if (event.data === '1rm_added') {
        this.loadBasic()
      }
    },
    refreshDashboard() {
      this.loadBasic()
      this.loadDailyEnergyIn()
      this.loadStrength()
      this.loadLastActivities()
      this.loadUserStats()
    },
    showAddMeasurement() {
      window.postMessage('show_add_measurement', '*')
    },
    showAddFood() {
      window.postMessage('show_add_food', '*')
    },
    showAddActivity() {
      window.postMessage('show_add_activity', '*')
    },
    refreshPage() {
      this.currentPage = 0
      this.reload = true
      this.loadUserStats()
    },
    calculateImperial() {
      this.metricWeight = this.basic.weight
      this.imperialWeight = this.twoDigits(
        this.basic.weight * (1 / this.factor)
      )
    },
    calculateMetric() {
      this.imperialWeight = this.basic.weight
      this.metricWeight = this.twoDigits(this.basic.weight * this.factor)
    },
    async loadStrength() {
      return axios
        .get(this.baseURL + '/stats/strength/current/yes/date', {})
        .then(response => {
          if (response.status == 200) {
            this.strength = response.data.data.slice(0, 5)
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    async loadLastActivities() {
      try {
        this.loaded = false
        const response = await this.api.get('/activities/last', {})
        if (response.status === 200) {
          this.lastActivitiesList = response.data.data
          localStorage.setItem(
            'activities_recent',
            JSON.stringify(this.lastActivitiesList)
          )
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
        }
      } catch (error) {
        null
      }
    },
    async loadDailyEnergyIn() {
      try {
        this.loaded = false
        const response = await this.api.get(
          `/nutrition/calories/in/${this.now(this.offset, true)}`
        )
        if (response.status === 200) {
          this.energyIn = response.data.data
          this.loaded = true
        } else {
          this.loaded = true
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
        }
      } catch (error) {
        this.loaded = true
        null
      }
    },
    loadBasic() {
      this.loaded = false
      let type = this.metric ? 'metric' : 'imperial'
      return axios
        .get(this.baseURL + '/stats/basic/' + type, {})
        .then(response => {
          if (response.status == 200) {
            this.basic = response.data.data
            if (this.metric) this.basic.height = this.basic.height / 2.54
            this.feet = Math.floor(this.basic.height / 12)
            this.inches = Math.floor(parseFloat(this.basic.height % 12))
            this.cm = this.oneDigit(this.basic.height * 2.54)

            if (this.metric) this.calculateImperial()
            else this.calculateMetric()
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadUserStats() {
      return this.api
        .get('/stats/volume30')
        .then(response => {
          if (response.status === 200) {
            this.timedout = false
            const { volume, reps, moving } = response.data.data
            this.volumeStats = volume
            this.reps = reps
            this.moving = moving
            this.hasIron = volume?.volume_metric > 0
            this.hasMovement = moving && moving?.distance_metric != null
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
          }
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED') {
            this.timedout = true
          } else {
            null
          }
        })
    }
  }
}
</script>
<style scoped>
.progress-bar-text {
  display: flex;
  justify-content: flex-start; /* Aligns text to the left */
  align-items: center; /* Vertically centers text */
  height: 100%; /* Ensures it takes the full height of the progress bar */
}
</style>
